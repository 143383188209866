   /* ####################################################################################

                                    POSTCSS

/* ####################################################################################*/
:root{
    --color-blue: #043146;
    --color-white2: rgba(255,255,255,0.5);
    --color-white3: rgba(255,255,255,0.2);
    --color-grey1: #e7e8e9;
    --color-grey2: #f1f2f2;
    --color-grey3: #595a5c;
    --color-black: #231f20;
    --color-black2: rgba(29,29,27,0.92);
    --color-black-main: #333333;
    --color-yellow: #ffd64f;

    --not-desktop: "only screen and (max-width: 767px)";
    --phone: "only screen and (max-width: 474px)";   
    --not-phone: "only screen and (min-width: 768px)";
    --not-desktop: "only screen and (max-width: 767px)";
    --larger-991: "only screen and (min-width: 992px)";
    --smaller-menu: "only screen and (max-width: 1024px)";
    --larger-menu: "only screen and (min-width: 1025px)";

};

$phone: "only screen and (max-width: 474px)";
$not-phone: "only screen and (min-width: 768px)";
$not-desktop: "only screen and (max-width: 767px)";
$larger-991: "only screen and (min-width: 992px)";
$smaller-menu: "only screen and (max-width: 1024px)";
$larger-menu: "only screen and (min-width: 1025px)";

@font-face{
    font-family: 'gotham-black';
    src: url('../fonts/Gotham-Black.otf');
}
@font-face{
    font-family: 'gotham-bold';
    src: url('../fonts/Gotham-Bold.otf');
}
@font-face{
    font-family: 'gotham-light';
    src: url('../fonts/Gotham-Light.otf');
}

h1{
    font-family: 'gotham-black';
}

h2,h3,h4,h5,h6{
    font-family: 'gotham-bold';
}

p{
    font-family: 'gotham-light';
}