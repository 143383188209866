.product_Header h2{
	margin-top: 0;
	font-family: 'gotham-bold';
	font-size: 20px;
}

.budget {
	margin-bottom: 10px;
	p{
		margin-bottom: 5px;
	}
	.price{
		font-family: 'gotham-bold';
		font-size: 30px;
		line-height: 30px;
	}
}

.quote-btn.enquiry .form-group button{
	padding: 10px 15px;
}

footer img{
	margin-top: 0;
	float: left;
}

.slider-wrapper{
	background: var(--color-black);
	padding: 50px 10px;
	margin-top: -20px;
	margin-bottom: 50px;

	.slick-arrow{
		@media (--phone){
			display: none !important;
		}
	}

	.slick-prev,.slick-next{
		padding: 10px;
		/*border: 1px solid white;*/
		border-radius: 50%;
		display: flex;
		justify-content: center;
		opacity: 0.7;
	}
	.slick-prev:before, .slick-next:before{
		font-family: 'fontAwesome';
		top: 50%;
	    position: absolute;
	    transform: scale(.5,1) translateY(-50%);
	    font-size: 30px;
	    font-weight: 600;
	}

	.slick-prev:before{
		content: "<";
	}

	.slick-next:before{
		content: '>';
		right: 3.5px;
	}

	.slick-prev{
		left: 5%;
	}
	.slick-next{
		right: 5%;
	}

	.slick-arrow{
		@media only screen and (min-width: 1281px){
			top: 50%;
		}
	}

	.project-slider{
		@media only screen and (min-width: 475px){
			margin-bottom: 30px;
		}

		.slick-slide img{
			/*max-width: 1000px;*/
			margin: auto;
			width: auto;
			max-height: 500px;
			max-width: 100%;

			@media only screen and (max-width: 474px){
				max-height: 360px;
			}
		}
		.slick-track{

			display: flex;
			align-items: center;
			justify-content: center;
		}

		.slick-slide{
			text-align: center;

			iframe{
		    	border: none !important;
		    }
		}

		button.slick-arrow{
			/*display: none !important;*/
		}


	}
	.slider-count {
    text-align: center;
    margin-bottom: 20px;
}
}

.view .navbar{
	margin-bottom: 0;
}


.slider-nav{
	.slick-track{
		margin-right: auto;
		margin-left: 0;

		/*@media only screen and (max-width: 474px){*/
			width: 6000% !important;
		/*}*/
	}
	.slick-slide{
		height: 98px !important;
		img{
			height:98px;
			object-fit: cover;
			border: 2px solid #231f20;
		}
	}
	.slick-slider{
		position: static;
	}
	.slick-arrow{
		font-size: 0;
		display: none !important;
	}

	/*.slick-arrow{
		display: none !important;
	}*/
}
/*.slider-nav{ 
	.slick-track{
		transform: none !important;
		width: 101% !important;
	}	
	.slick-slide {
	    width: 100px !important;
	    margin: 5px;

	    img{
	    	height: 100px;
	    	width: 100px;
	    	object-fit: cover;
	    }
	}
	@media only screen and (max-width: 767px){
		.slick-arrow{
			display: none;
		}
	}
}*/

.like{ 
		/*padding: 0;*/

		@media only screen and (max-width: 767px){
			margin-top: 30px;
		}
	h5 {
	    font-size: 18px;
	    font-family: gotham-light;
	}

	.product-list .products li {
		.img-wrap .picture{
			height: 160px;

			@media (--phone){
				height: 255px;
			}


		}
		&:hover .thumb-wrap{
			height: 55px;
		}
	}
}
.product-detail{
	.cate-tag{
		margin-top: 15px;

		&>*:hover{
			background: var(--color-yellow);
			border-color: var(--color-yellow);
			color: var(--color-black);
		}
	}
}

.action-btn{
	margin-top: 10px;
	a{
		margin-right: 10px;
		font-size: 20px;
		color: var(--color-grey3);

		&:hover{
			color: var(--color-blue);
		}
		i.fa.fa-clone{
			font-weight: 900;
		}
	}

	.favorite{
		font-size: 0 10px;
		padding: 5px 7px;

		&:before{
			font-size: 22px;
			font-weight: 900;
		}
	}

}

.tooltip-share{
	visibility: hidden;
	  width: 50px;
	  background-color: #555;
	  color: #fff;
	  text-align: center;
	  border-radius: 6px;
	  padding: 5px;
	  position: absolute;
	  z-index: 1;
	  bottom: 125%;
	  left: 50%;
	  opacity: 0;
	  transition: opacity 0.3s;
	  font-size: 14px;
	  transform: translateX(-50%);

	   &:after{
	  	content: "";
		  position: absolute;
		  top: 100%;
		  left: 50%;
		  margin-left: -5px;
		  border-width: 5px;
		  border-style: solid;
		  border-color: #555 transparent transparent transparent;
	  }
}

.share-btn{
	display: inline-block;
	position: relative;
	&:hover {
		.tooltip-share{
			visibility: visible;
			opacity: 1;
			width: 30px;
			bottom: 115%;
			height: 30px;
		}
	}
}
.tooltip-share{
	padding-bottom: 1px;
  a{
  	color: white;
	/*margin: 0 10px;*/
	font-size: 15px;
  	&:hover{
  		color: white;
  	}
  }
}

.video-wrap{
	max-width: 750px;
	padding: 0 15px;
	padding-top: 40%;
	margin: auto;

	@media only screen and (max-width: 474px){
		padding-top: 80%;
	}
}
.slider-nav .slick-current img {
    border: 2px solid white;
}

.fav-wrap{
	position: relative;
	display: inline-block;

	&:hover{
		.tooltip-fav{
			visibility: visible;
			opacity: 1;
			bottom: 115%;
		}
	}
}