.container-fluid.products {
    /*margin-top: 10px;*/

 /*   &>.row>div:first-child{
		margin-top: 8px;
    }*/
	.row{
		@media only screen and (min-width: 768px){
		    &>div:first-child{
		    	width: 20%;
		    }
		    &>div:last-child{
		    	width: 80%;
		    }
		}
	}
}

.home .container-fluid.products{
	.row{
		&>div:first-child{
			width: 100%;
		}
		&>div:last-child{
			width: 100%;
		}
	}
}
.side-filter{

	@media only screen and (max-width: 767px){
		margin-bottom: 20px;
	}

	.reset-filter{
		text-align: right;
		font-size: 12px;
		margin-bottom: 10px;
	}
	h5{
		font-family: 'gotham-bold';
		font-size: 14px;
		margin: 0;
		padding: 10px 15px;
		background: var(--color-grey1);
	}

	a{
		color: var(--color-grey3);
		font-family: 'gotham-light';
		font-size: 14px;
		display: block;
		position: relative;
		z-index: 999;

		&.active{
			color: black;
			font-family: 'gotham-bold';
		}

		&:hover{
			text-decoration: none;
			color: black;
		}
	}
	.list{
		background: #f7f7f7;
		padding: 20px 15px;
		&>div{
			margin-bottom: 10px;
			position: relative;
			
			&:last-child{
				margin-bottom: 0;
			}
		}
		a{
			font-family: gotham-bold;
			font-size: 13px;
		}
	}
	.category .list>div>a.active:after{
		content: '\f00d';
		position: absolute;
		right: 0;
		top: 0;
		font-family: 'fontAwesome';
	}

	.price-range{
		.list{
			text-align: center;
		}

		.slider-handle{
			background-color: var(--color-blue); 
			background-image: none;
		}
		#w4-slider .slider-selection {
		    background: var(--color-blue);
		}
		.slider-track-high,
		.slider-track-low{
			background: rgba(0,0,0,0.1);
		}
		.slider.slider-horizontal{
			width: 160px;

			@media only screen and (max-width: 474px){
				width: 270px;
			}
		}

		button{
			margin-left: 20px;
			background: #bdbec0;
			border: none;
			color: white;
		}
	}

}

.product-list{


	h2{
		font-size: 30px;
		margin-top: 10px;
		font-family: 'gotham-bold';
		margin-bottom: 0;


		@media only screen and (max-width: 767px){
			font-size: 24px;
		}
	}

	&>h2{
		margin-top: 0;
	}
	.products{
		padding: 0;
		color: black;
		overflow: hidden;

		li{
			list-style: none;
			color: black;
			.img-wrap{
				position: relative;

				.picture{
					height: 195px;
    				object-fit: cover;
    				overflow: hidden;

    				img{ 
    					height: 100%;
    					object-fit: cover;
    				}

    				@media (--phone){
    					height: 235px;

    					img{
    						height: 100%;
    						object-fit: cover;
    					}
    				}
				}

				.thumb-wrap{
					overflow: hidden;
					position: absolute;
					bottom: 0;
					left: 0;
					background: var(--color-grey2);
					height: 0;
					transition: height 0.3s ease-out;

					.thumbnail{
						float: left;
					    width: 33.33%;
					    margin-bottom: 0;
					    padding: 0;
					    border-radius: 0;
					}
				}
				p.img-count {
				    font-family: gotham-light; 
				    font-size: 13px;
				    position: absolute;
				    z-index: 9;
				    top: 3px;
				    left: 6px;
				    color: white;
				    text-shadow: 1px 1px 3px black;
				}
			}
			&:hover{
				.thumb-wrap{
					height: 65px;
					transition: height 0.3s ease-out;
				}
			}

			a{
				text-decoration: none;
			}
			
			@media only screen and (min-width: 768px){
				width: 32.3%;
			    float: left;
			    margin-right: 1%;
			    margin-bottom: 1%;
			}
			@media only screen and (max-width: 767px){
			    margin-bottom: 5%;
			}


			h2{
				font-family: 'gotham-bold';
				font-size: 16px;
				text-transform: capitalize;
				color: var(--color-black-main);
				margin-bottom: 10px;
				
				/*truncate line*/
			    overflow: hidden;
		        display: -webkit-box;
			    -webkit-line-clamp: 2;
			    -webkit-box-orient: vertical;
			    height: 35px;
			}

			&:hover h2{
				color: var(--color-blue);
				text-decoration: underline;
			}
		}

		.price{
			font-size: 18px;
			font-family: 'gotham-bold';
			color: var(--color-black-main);

			.rm{
				font-size: 14px;
				display: block;
				line-height: 8px;
				font-family: 'gotham-light';
			}
		}
		.add-quote{
			display: flex;
			justify-content: flex-end;
			width: 100%;
			align-items: center;

			.share-btn{  
				a{
			    font-size: 17px;
			    color: #666;
				}

			    .tooltip-share a{
			    	color: white;
			    }
			}

		}
		.quote-row{
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
		    position: absolute;
		    bottom: 10px;
		    right: 0;
		    width: 100%;
		    padding: 0 10px;
		}

		.description{
			background-color: var(--color-grey2);
			padding: 10px;
			min-height: 180px;
			position: relative;
		}
	}
	
}

.quote-btn{
	.form-group{
	margin-bottom: 0;	
	margin-right: 5px;

	.btn-primary{
		background: #ffd64f;
		text-transform: uppercase;
		font-family: 'gotham-bold';
		color: black;
		border: none;
		border-radius: 0;
		font-size: 12px;
		padding: 5px 7px;
	}
}
}

.products .cate-tag{
	height: 50px;
    overflow: hidden;
}
.cate-tag{
	margin-bottom: 20px;

	&>* {
	    display: inline-block;
	    /*color: #44b8ad;
	    border: 1px solid #44b8ad;*/
	    /*color: var(--color-black-main);*/
	    color: #676767;
	    border: 1px solid #676767;
	    /*border: 1px solid var(--color-black-main);*/
	    border-radius: 20px;
	    padding: 0 5px;
	    padding-top: 2px;
	    margin-bottom: 5px;
	    transition: .3s ease-out all;
	    font-size: 12px;

	    @media (--phone){
	    	padding-top: 0;
	    }

	    &:hover{
	    	background-color: #1c2f3b;
	    	color: white;
	    	border: 1px solid #1c2f3b;
	    	transition: .3s ease-out all;
	    }
	}
	}

.pagination{ 
	display: flex;
    width: 100%;
    justify-content: center;

	li a,li span {
	    border-radius: 50% !Important;
	    margin: 0 5px;
	    color: var(--color-blue);
	    border-color: var(--color-blue);
	    transition: all 0.3s;

	    &:hover{
	    	background-color: var(--color-blue);
	    	color: white;
	    	transition: all 0.3s;
	    }
	}

	li:last-child>a{
		border-radius: 50%;
	}

	li:first-child> span{
		border-radius: 50%;
	}

	.active a{
		background-color: var(--color-blue);
		color: white;
		border: none;

		&:hover{
			background-color: var(--color-blue);
		}
	}
}

.contact-form .thank-you {
	text-align: center;
	padding: 30px 0 50px;
	h2 {
		font-family: 'gotham-black';
	    font-size: 70px;
	    color: var(--color-blue);

	    @media only screen and (max-width: 767px){
	    	font-size: 50px;
	    }
	}
	 p{
	 	color: var(--color-blue);
		font-size: 25px;

		@media only screen and (max-width: 767px){
	    	font-size: 18px;
	    }
	 }
}

.home{
	.products{
		.col-md-9{
			width: 100%;
			
			.product-list{
				&>h2{
					text-align: center;;
				}
				&>p{
					text-align: center;
				}
			}
			@media only screen and (min-width: 768px){
				.products>li{
					width: 24.2%;

					&:nth-child(4n){
						margin-right: 0;
					}
				}
			}
		}

	}
}

.headline{
	text-align: center;
	margin-bottom: 20px;
	h2{
		margin-top: 0;
	}


}

.cate-btn.hover-gold{
	text-align: center;
	margin-bottom: 30px;

	button{
		margin: 0 20px;
		width: 130px;
		padding: 3px 0 1px;
		font-family: gotham;
		font-size: 14px;
		height: 30px;

		a{
			padding: 3px 0;
		}

		@media only screen and (max-width: 474px){
			margin-bottom: 10px;	    
		    margin-left: 5px;
		    margin-right: 5px;
		    width: 115px;
		}
	}
}

.favorite{
	width: 30px;
	height: 26.25px;
	padding: 10px;
	/*background-image:url('../images/favorite.png');*/
	background-position: center;
	background-repeat: no-repeat;
	border: none;
	/*background-color: var(--color-blue);*/
	transition: all 0.3s ease-out;
	background-size: 18px;
	position: relative;

	&:before{
		content: '\f097';
	    font-family: fontAwesome;
	    font-size: 17px;
	    bottom: 0;
	    left: 50%;
	    transform: translate(-50%);
	    position: absolute;
	    color: #666666;
	    transition: all 0.1s ease-out;
	}

	&:hover:before{
		content: '\f02e';
		color: #66666;
		transition: all 0.1s ease-out;
	}

	&.favorited:before{
		/*background-color: #44b8ad;*/
		content: '\f02e';
		color: #043146;
		transition: all 0.1s ease-out;
	}

}

.popup-tooltip{
	position: relative;
	text-decoration: none !important;
	:hover{
		
		cursor: pointer;
	}
}
.tooltiptext {
	  visibility: hidden;
	  width: 90px;
	  background-color: #555;
	  color: #fff;
	  text-align: center;
	  border-radius: 6px;
	  padding: 5px;
	  position: absolute;
	  z-index: 1;
	  bottom: 130%;
	  left: 155%;
	  margin-left: -75px;
	  opacity: 0;
	  transition: opacity 0.3s;
	  font-size: 14px;
	  font-family: gotham-light;

	  &:after{
	  	content: "";
		  position: absolute;
		  top: 100%;
		  left: 50%;
		  margin-left: -5px;
		  border-width: 5px;
		  border-style: solid;
		  border-color: #555 transparent transparent transparent;
	  }
}

.action-btn>a:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.home .sort-bar {
    display: none;
}

.sort-bar{
	text-align: right;
	padding-right: 10px;
	margin-bottom: 10px;
	@media only screen and (max-width: 474px){
		padding-right: 0;
	}
	label{
		color: var(--color-grey3);
		font-family: gotham-light;
	}
	form{
		display: inline-block;

		@media (--phone){
			width: 290px;
		}
	}
	select {
	    font-family: gotham-bold;
	    border-color: #cecece;
	    color: var(--color-grey3);
	    padding: 5px;
	    margin-left: 5px;

	    &:focus{
	    	border-color: #cecece !important;
	    }

	    @media only screen and (max-width: 474px){
	    	width: 100%;
	    	margin-left: 0;
	    }


	    option{
	    	font-family: gotham-light;
	    }
	}
}

.cate-btn.hover-gold {
    display: none;
}
.home .cate-btn.hover-gold {
    display: block;
}

.products .headline-wrap.product-headline{
	margin-bottom: 10px;

	@median (--phone){
		margin: 30px 0;
	}
}
.headline-wrap.product-headline {

	@media only screen and (min-width: 475px){
	    display: flex;
	    justify-content: space-between;
	    align-items: flex-end;
	}

	@media only screen and (max-width: 474px){
		display: block;

		.sort-bar{
			text-align: center;
		}
	}

    .headline{
    	margin-bottom: 0;
    	text-align: left;

    	@media only screen and (max-width: 474px){
    		text-align: center;
    	}
    }
}

.headline-wrap{
		a{
		font-family: 'gotham-light';
	}
}

.tab-pane {
    display: none;
}

.tab-pane.active {
    display: block;
}

.fav-wrap.product-fav{
	
	margin-left: 5px;

	.favorite{
		padding: 4px 10px;
	}

	&:hover .tooltip-fav{
		left: 40%;
		bottom: 130%;

		&:after{
			left: 85%;
		}
	}
}

.play-icon {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: white;
    text-shadow: 0px 0px 23px black;
}

.btn.filter-btn{
	width: 100%;
    border-radius: 0;
    background: var(--color-grey1);
    color: var(--color-black-main);
    border: none;
    margin-bottom: 10px;
    text-align: left;
    font-size: 14px;
    font-family: 'gotham-bold';
    position: relative;

    &:focus{
    	background: var(--color-grey1) !important;
    }

    &:after{
    	content: '\f068';
	    font-family: fontAwesome;
	    font-size: 12px;
	    right: 15px;
	    position: absolute;
	    bottom: 6px;
    }
	
	&.collapsed:after{
		content: '\f067';
	} 

	@media only screen and (min-width: 768px){
		display: none;
	}
	

}