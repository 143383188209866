section.compare{
	margin-top: 50px;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #1c2f3b;

	.compare-wrapper{
		text-align: center;
		color: white;
		padding: 0 15px;

		h2{
			font-size: 40px;
			margin-bottom: 5px;

			@media only screen and (max-width: 767px){
				font-size: 25px;
			}
		}
		p{
			margin-top: 10px;
		}

		a{
			background: none;
			border-radius: 30px;
			margin-top: 30px;
			padding: 15px 70px;
			border: 1px solid var(--color-white2);
			font-family: 'gotham-bold';
			letter-spacing: 2px;
			color: white;
			transition: all 0.3s ease-out;
			text-decoration: none;
			margin-top: 30px;
			display: inline-block;

			&:hover{
				background: white;
				color: var(--color-blue);
				transition: all 0.3s ease-out;
			}
		}
	}
}
.footer{
	margin-top: 20px;

	p{
		font-size: 14px
	}
	hr{
		border-top: 1px solid var(--color-grey1);
		margin: 10px 0;
	}



	.top-footer{
		color: var(--color-blue);
		overflow: hidden;
		
	@media only screen and (max-width: 767px){
		&>*{
			display: block;
			margin-bottom: 20px;
			overflow: hidden;
		}
	}
		img{
			max-width: 120px;
			padding-top: 10px;
		}
		h6{
			margin-bottom: 5px; 
		}

		@media only screen and (min-width: 768px){
			&>div:nth-child(2){
				width: 45%;
			}
			&>div:nth-child(3){
				width: 22%;
			}
			&>div:nth-child(4){
				width: 16%;
			}
		}
	}

	

	.bottom-footer{
		@media only screen and (max-width: 767px){
			p{
				margin-bottom: 5px;
				float: none !important;
			}
		}
	}
}

.float-chat {
    position: fixed;
    right: 30px;
    bottom: 170px;

    .btn{
    	padding: 10px;
    	border-radius: 50%;
    	transition: 0.3s all;
    	background: #ccc;
    	height: 50px;
    	width: 50px;
    	display: flex;
    	align-items: center;
    	justify-content: center;

    	&:before{
    		content:"";
			background: linear-gradient(#a57d23,#f1dd7f 32%,#fbf2a9 50%,#f1dd7f 72%,#a57d23);	
			height: 0%;
		    width: 100%;
		    position: absolute;
		    left: 50%;
		    transform: translate(-50%);
		    top: 0;
		    border-radius: 50%;
		    opacity: 0;
		    transition: .3s width ease-out, .2s opacity ease-out;
    	}

    	&:hover:before{
    		transition: .3s width ease-out, .2s opacity ease-out;
    		opacity: 1;
    		height: 100%;
    	}
    }

    i{
    	font-size: 20px;
    	color: var(--color-black);
    	position: relative;
    	z-index: 99;
    }
}

.float-whatsapp{
	position: fixed;
    right: 30px;
    bottom: 110px;
    height: 50px;
    width: 50px;
   	padding: 20px;
	border-radius: 50%;
	background: #25D366; 
    display: flex;
    align-items: center;
    justify-content: center;

    a{


    	i{
    		color: white;
    		font-size: 30px;
    	}
    }
}

.float-messenger{
	position: fixed;
    right: 30px;
    bottom: 50px;
    height: 50px;
    width: 50px;
   	padding: 20px;
	border-radius: 50%;
	background: #0078FF; 
    display: flex;
    align-items: center;
    justify-content: center;

    a{


    	i{
    		color: white;
    		font-size: 30px;
    	}
    }
}