.modal-dialog{
	@media only screen and (min-width: 768px){
		width: 80%;

		.modal-body{
			width: 80%;
			margin: auto;
		}
	}
}

.modal-header{
	border-bottom: 0;
	position: absolute;
	right: 0;
	z-index: 99999;
}

.summaryTitle h2 {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
}
/*.summaryCart{
	.summaryTitle h2{
		border-bottom: none;
	}
}*/


.summaryDesc{ 
	overflow: hidden;
	margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--color-black);

    &:last-child{
    	border-bottom: none;
    }

	.code{
		display: flex;
		align-items: center;

		h2{
			text-transform: uppercase;
			font-size: 25px;
			margin: 0;
		}
		h3{
			margin: 0;
		}

		.quantity{
			margin: 0;
		}

		.col-sm-2:last-child{
			display: flex;
			justify-content: space-between;
		}
	}
}

.modal-content .modal-body{
	.contact-sec{
		/*margin-top: 50px;*/
		margin-bottom: 30px;
		background: none;
		overflow: hidden;

		@media only screen and (min-width: 768px){
			width: 85%;
		    margin-left: auto;
		    margin-right: auto;
		}

		.contact-form{
			margin-bottom: 0;

			input{
				border-color: #ccc;
				color: var(--color-black);
				width: 100%;
				font-weight: 400;

				&::placeholder{
					color: var(--color-black);
					opacity: 0.5;
				}
				&:focus{
					border: 1px solid #ccc !important;;
				}
			}
			.row1{
				@media only screen and (min-width: 768px){
					display: flex;
					justify-content: space-between;
				}
				.form-group{
					width: 32.33333%;
					/*margin-right: 1%;*/
					float: left;
					margin-bottom: 0;

					@media (--phone){
						width: 100%;
					}
				}
			}

			.row2{
				.form-group:first-child input{
					width: 65.66666%;
					margin-right: 1%;
				}
				.form-group:last-child input{
					width: 32.3333%;
					margin-right: 1%;
				}
			}
			/*temporary*/
			.row2{
				display: flex;
				justify-content: space-between;

				&>*{
					width: 49%;
				}
			}

			.last-row{
				textarea{
					width: 100%;
					/*margin-right: 1%;*/
				    background: transparent;				    
				    height: 85px;
				    border-radius: 25px;
				    padding: 10px 20px;
				    font-family: gotham-light;
				    /*letter-spacing: 2px;*/
				    margin-bottom: 15px;
				    border: 1px solid #ccc;
				    line-height: 30px;
				    color: var(--color-black);

				    &::placeholder{
				    	color: var(--color-black);
				    	opacity: 0.5;
				    }
				}
			}

			.contact-btn{
				display: flex;
				justify-content: center;

				button{
					position: relative;
					color: var(--color-black);
					border-color: var(--color-black);
					font-family: gotham-bold;

					&:after{
					    content: '';
					    width: 0%;
					    height: 1px;
					    background: var(--color-black);
					    position: absolute;
					    bottom: 0;
					    left: 50%;
					    transform: translate(-50%);
					}

					&:hover:after{
						width: 100%;
						transition: .3s all ease-out;

					}

				}

			}
		}
	}
}

.item-loop {
    width: 80%;
    margin: auto;
    margin-top: 50px;
}

.modal-header .close{
	opacity: 0.8;
	color: var(--color-black);
}

.summary{
	margin-top: 60px;
	.code h2, .codeRemark h2{
		width: 100%;
		font-size: 18px;
		margin-top: 10px;
	}
	.col-sm-2.del-item {
	    position: absolute;
	    padding: 0;
	    cursor: pointer;
	    top: 0;
	}
	.img-container{

		@media (--phone){
			text-align: center;
		} 
		img {
		    width: 80%;
		    float: none;
			max-height: 90px;
		    object-fit: cover;
		    object-position: top;

		    @media (--phone){
		    	max-height: 180px;
		    }
		}
	}
}

.summary .empty p {
    color: black;
    padding: 0 15px;
}
