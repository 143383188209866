.signup{
	h1.pageHeader{
		text-align: center;
		margin-bottom: 30px;
		font-size: 20px;
	}
	.col-md-offset-3{
		margin-left: 0;
		width: 100%;

		.page-user-signin-signup>.row{
			display: flex;
			justify-content: center;
		}

	}
}

.login{
	h1.pageHeader{
		text-align: center;
		margin-bottom: 30px;
		font-size: 20px;
	}
	.col-lg-12{
		&>.row{
			display: flex;
			align-items: center;
			justify-content: center;
			.col-md-offset-3{
				margin-left: 0;
				width: 100%;

				@media (min-width: 1200px){
				width: 41.66666667%;
				}
			}
		}
		.divider{
			text-align: center;
			text-transform: lowercase;
		}
		#w0{
			display: flex;
			align-items: center;
			justify-content: center;
			a{
				margin-left: 0;
	   			margin-right: 0;
	   			/*margin-top: 15px;*/
	   			width: fit-content;
			}

			i{
				color: #777;
			}
		}
	}
	
	

}

.signup{
	.divider{
			text-align: center;
			text-transform: lowercase;
		}
		.divider+#w1{
			display: flex;
			align-items: center;
			justify-content: center;
			a{
				margin-left: 0;
	   			margin-right: 0;
	   			/*margin-top: 15px;*/
	   			width: fit-content;
			}

			i{
				color: #777;
			}
		}
	.col-lg-5{
		@media only screen and (max-width: 474px){
			width: 100%;
		}
	}
}
.page-user-signin-signup,.page-user-signin-login{
.control-label{
	font-family: 'gotham-bold';
	display: flex;
}

	.btn-primary{
		/*margin: 0 30px;*/
		background: none;
		border: none;
		color: var(--color-black);
		font-family: 'gotham-light';
		transition: all 0.3s ease-out;
		/*margin-top: 30px;*/
		border: 1px solid rgba(0,0,0,0.5);
		border-radius: 20px;

		&:hover{
				font-family:'gotham-bold';
				transition: all 0.3s ease-out;
			}
		}
		input{
			border-radius: 20px;
			font-family: 'gotham-light';
			border: 1px solid #ccc !important;
		}
	
}
.request-password-reset{
	.forgotPassword{
		text-align: center;
		font-family: 'gotham-bold';
	}

	#request-password-reset-form{
		label{
			font-family: 'gotham-bold';
		}
		input{
			border-radius: 20px;
			font-family: 'gotham-light';
		}
		.btn-primary{
		/*margin: 0 30px;*/
		background: none;
		border: none;
		color: var(--color-black);
		font-family: 'gotham-light';
		transition: all 0.3s ease-out;
		margin-top: 30px;
		border: 1px solid rgba(0,0,0,0.5);
		border-radius: 20px;
		}
	}
}

.login-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 310px;
    margin: auto;
    margin-top: 20px;
    justify-content: space-between;

    
    .form-group{
		margin-bottom: 0;
    }
}