
section.contact-sec {
    background: url('../images/home-cover.jpg') no-repeat top center;
    background: black;
	
	h1,h6,p{
		color: white;
	}

	.contact-detail{
		margin-top: 200px;
		margin-bottom: 100px;

		h6{
			margin-bottom: 5px;
		}

		.split{
			margin-top: 40px;
			overflow: hidden;
			.col-sm-6{
				padding: 0;
				width: fit-content;
				padding-right: 40px;
				}
			}
		}

	.contact-form{
		margin-bottom: 100px;

		@media only screen and (max-width: 767px){
			margin-bottom: 50px;
		}
		h1{
			padding-bottom: 50px;
			width: fit-content;
			position: relative;
			font-size: 30px;

			&:before{
				content: '';
			    height: 3px;
			    width: 70px;
			    background: white;
			    position: absolute;
			    bottom: 30px;
			    right: -27px;
			
			}
		}

		input{
			background: transparent;
			border: 1px solid rgba(255,255,255,0.5);
			height: 35px;
			border-radius: 20px;
			padding: 10px 15px;
			font-family: 'gotham-light';
			/*letter-spacing: 2px;*/
			margin-bottom: 15px;
			float: left;
			float: none;
			width: 100% !important;
		}

		.contact-btn{
			text-align: center;

			button{
				margin: 0 30px;
				background: none;
				border: none;
				color:white;
				font-family: 'gotham-light';
				transition: all 0.3s ease-out;
				margin-top: 30px;

				&:hover{
					font-family:'gotham-bold';
					transition: all 0.3s ease-out;
				}
			}
		}

		.enquiry-form{

			input{
				color: white;
				&::placeholder{
					color: white;
				}
			}

			@media only screen and (min-width: 768px){
				.row1{
					.form-group{
						float: left;
					}
					.form-group:first-child,.form-group:nth-child(2){
						width: 39%;
						margin-right: 1%;
					}
					.form-group:last-child{
						width: 20%;
					}
				}

			}
			.alert-success{
				background: transparent;
			    border: none;
			    color: #4caf50;
			    font-family: gotham-bold;
			}
		}
	}	
}

.contact +.compare{
	margin-top: 0;
}

.gmap-sec {
	@media only screen and (max-width: 768px){
	margin: 15px 0;
}
}