.about-wrapper{
	margin-top: 50px;
	


	
	.col-sm-6{
			h1{
				margin-bottom: 60px;
			}
		p{
			margin-bottom: 60px;
			line-height: 32px;
			text-align: justify;

			@media only screen and (max-width: 767px){
				line-height: 24px;
			}
		}
		&:first-child{
			

			@media only screen and (min-width: 768px){
				padding-right: 30px;
				padding-left: 0;
			}
		}
		&:last-child{
			@media only screen and (min-width: 768px){
			padding-right: 0;
		}
			
			margin-top: 90px;

			@media only screen and (min-width: 768px){
				padding-left: 30px;
			}

			hr{
				width: 80px;
				margin: 50px 0;
				border-top: 4px solid black;
			}

			ul{
				padding: 0;
				list-style-type: none;

				li{
					font-family: 'gotham-light';
					padding-bottom: 15px;
					font-size: 18px;

					@media only screen and (max-width: 474px){
						padding-bottom: 10px;
						font-size: 16px;
					}
				}
			}
			
		}
	}

}