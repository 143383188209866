@import "variables";
@import "contact";
@import "about";
@import "footer";
@import "portfolio";
@import "single";
@import "popup";
@import "login";

@font-face{
    font-family: 'gotham-black';
    src: url('../fonts/Gotham-Black.otf');
}
@font-face{
    font-family: 'gotham-bold';
    src: url('../fonts/Gotham-Bold.otf');
}
@font-face{
    font-family: 'gotham-light';
    src: url('../fonts/Gotham-Light.otf');
}
h1{
    font-family: 'gotham-bold';
    font-size: 35px;
}
h2,h3,h4,h5,h6{
    font-family: 'gotham-bold';
}
p{
    font-family: 'gotham-light';
    font-size: 14px;
}
img{
	width: 100%;
}

input, textarea{
	-webkit-appearance: none !important;
}

.navbar-brand{ 
	height: 100%;

	img {
		max-width: 120px;
	}
}
:focus,
a:focus,
button:focus
img:focus,
.btn:active,
a:active,
button:active,
a:active:focus,
button:active:focus{
	background: none !important;
	color: inherit !important;
	border-color: transparent !important;
	box-shadow: none;
	outline: none !important;
}
textarea{
	resize: none !important;
}
.form-control:focus{
	box-shadow: none;
}
.container-fluid{
	max-width: 1400px;
}
.navbar-inverse.navbar .navbar-toggle{
	margin-top: 20px;
	border: none;
	background-color: transparent;
}
.navbar div.navbar-collapse{

	@media only screen and (min-width: 768px){
		display: flex !important;
	    justify-content: space-between;
	    align-items: center;
	    padding: 0;
	    margin-top: 13px;
	}

	.navbar-search {
		form{
/*			border-radius: 20px;
			border: 1px solid var(--color-white2);*/
	
			&:focus{
				border-color: white;
			}

			@media only screen and (max-width: 767px){
			margin-top: 20px;
			position: relative;
		}
		}
		input{
			background: none;
			border: 1px solid var(--color-white2);
			border-radius: 20px;
			padding: 5px 20px;
			color: white !important;
			font-family: 'gotham-light';

				&::placeholder{
					color: white;
					opacity: 0.3;
				}

				&:focus{
					/*outline: none;*/
					border-color: white;
					border: 1px solid rgba(255,255,255,0.7) !important;
				}
		
				@media only screen and (max-width: 767px){
					background: white;
					color: var(--color-blue);
					width: 100%;
				
				
				&::placeholder{
					color: var(--color-blue);
					opacity: 0.5;
				}
				}
		}
		button{
			background: none;
			border: none;
			color: var(--color-white2);
			top: 5px;
			right: 5px;
			position: absolute;
			opacity: 0.3;
		}
		input:focus+button{
			color: white;
		}
	}
	@media only screen and (max-width: 767px){
	color: #fff;
    background: #043146;
    position: relative;
    z-index: 99999;
    border-bottom: 1px solid black;

	ul{
		margin-bottom: 0;
    li{
    	border-bottom: 1px solid var(--color-white3);

    	&:last-child{
    		border-bottom: none;
    	}
    }
}
}
}

.navbar-inverse.navbar{
	border-radius: 0;
	height: 80px;

	.navbar-nav > .active > a{
		background: none;
		font-weight: 700;

		&:after{
		    width: 25px;
		    background-color: #ffd64f;
		}
	}
	.navbar-nav > li > a{
		color: white;
		font-size: 14px;
		font-family: 'gotham-bold';
		text-transform: uppercase;

		@media only screen and (min-width: 768px){
		&:after{
		    content: '';
		    height: 1px;
		    width: 0;
		    background: white;
		    position: absolute;
		    left: 14px;
		    bottom: 15px;
		    transition: all 0.2s ease-out;
		}

		&:hover:after{
			width: 25px;
			transition: all 0.2s ease-out;
		}
	}
	}
	@media only screen and (min-width: 768px){
	.navbar-nav li:last-child a:after{
		left: 6px;
	}
}

	/*.navbar-nav li:last-child a:after{
		content: none;
	}*/

	.nav-reg a{
		padding-right: 0px;

		@media only screen and (max-width: 767px){
			span{
				display: none;
			}
			i{
				display: none;
			}
		}
	}
	.nav-log a{
		@media only screen and (min-width: 768px){
		padding-left: 5px;
	}
	}

	
	.nav-cart a{
		@media only screen and (min-width: 768px){
			border: 1px solid white;
		    border-radius: 50%;
		    padding: 10px 10px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    margin-top: 5px;

		    span{
		    	display: none;
		    }
		}

		@media only screen and (max-width: 767px){
			i{
				display: none;
			}
		}
}
}
.navbar{
	background: url('../images/menubar.jpg') no-repeat center;
	border:none;
	background-size: cover;
}
.navbar-search{
	position: relative;
	form:before{
		/*content:'\f002';
		position: absolute;
		top: 0;
		right: 0;
		padding: 6px 10px;
		font-family: 'fontAwesome';
		color: white;
		opacity: 0.5;*/

		@media only screen and (max-width: 768px){
			color: var(--color-blue);
		}
	}


}
.home,.contact{ 
.navbar{
	margin-bottom: 0;
	background: transparent;
	border: none;
	width: 100%;
	position: absolute;
}
}
section.home-content {
    
    background: url('../images/home-cover.jpg') no-repeat top center;
    background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (min-width: 768px){
		height: 120vh;
	}

	@media only screen and (min-width: 1600px){
		height: 100vh;
	}

	@media only screen and (max-width: 767px){
		height: inherit;
		padding-bottom: 15px;
	}

	.home-wrapper{
		margin-top: 170px;

		@media only screen and (min-width: 768px) and (max-width: 1440px){
		margin-top: 100px;
		}
	}

    h1,p{
    	color: white;
    	text-align: center;
    }

    h1{
    	@media only screen and (max-width: 767px){
    		font-size: 24px;
    	}

    }
    p{
    	font-size: 2rem;

    	@media only screen and (max-width: 767px){
    		font-size: 14px;
    	}
    }

    .browse-btn{
    	text-align: center;
    	margin-top: 20px;
    	background: var(--color-grey);
		button{
			@media only screen and (max-width: 767px){
			    	margin-bottom: 20px;
		    	}
		}
    	a{
    		width: 250px;
		    border-radius: 20px;
		    border: none;
		    /*margin: 0 50px;*/
		    padding: 10px 0px;
		    color: var(--color-black-main);
		    background: none;

		    @media only screen and (max-width: 767px){
			    	font-size: 14px;
		    	}

		    &:first-child{
/*		    	opacity: 0.7;
		    	background: white;*/

		    	
		    }
/*		    &:last-child{
				background: #a57d23;
				background: linear-gradient(90deg, #a57d23 0%,#f1dd7f 32%,#fbf2a9 50%,#f1dd7f 72%,#a57d23 100%);
		    }*/
    	}
    }
    .home-search{
    	margin-top: 70px;
    	text-align: center;

    	.hashtag{
    		width: 60%;
    		margin: auto;
    		@media only screen and (max-width: 768px){
	    		a{
					padding: 0;
					display: inline;
	    		}
	    	}
    	}


    	@media only screen and (min-width: 768px) and (max-width: 1440px){
    		margin-top: 50px;
    	}

    	@media (--phone){
    		margin-top: 50px;
    	}

    	h6{
    		color: white;
    		font-size: 16px;
    		font-family: 'gotham-bold';
    		margin-bottom: 20px;
    	}
    	a{
    		font-family: 'gotham-light';
    		color: white;
    		font-size: 16px;
    		padding: 0 5px;
    		text-decoration: underline;

    		@media only screen and (max-width: 767px){
			    	display: block;
		    	}
    	}
    }
    .award{
    	margin-top: 50px;
    	text-align: center;
    	@media only screen and (min-width: 768px) and (max-width: 1440px){
    		margin-top: 10px;
    	}

    	@media only screen and (min-width: 768px){
	    	display: flex;
	    	justify-content: center;
    	}

    	@media only screen and (max-width: 767px){
    		width: 90%;
	    	margin: auto;
	    	margin-top: 50px;
    	}

    	&>div{
			@media only screen and (max-width: 767px){
				display: inline-block;
				width: 32.6%;
			}
    		 p {
			    font-size: 12px;
			    width: 115px;
			    height: 100px;
			    margin: 10px 20px;
			    background: url(../images/award.png) no-repeat 50%;
			    color: #fee2bc;
			    background-size: contain;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    padding-bottom: 20px;

			    @media only screen and (max-width: 767px){
			    	width: 100%;
			    	float: left;
			    	margin: 0 2px 20px;

			    	/*&:last-child{
			    		width: 100%;
			    	}*/
			    }
			  }
		    }
		}
}

section.contact-sec {
    background: url('../images/home-cover.jpg') no-repeat top center;
    background-size: cover;
}
section.home-content {
    margin-bottom: 30px;
}

.home{
.navbar-inverse.navbar{

	.navbar-nav > .active > a{

		&:after{
		    width: 0;
		}
		&:hover:after{
			width: 25px;
		}
	}
	.navbar-nav> li:first-child > a{

		&:after{
		width: 25px;
	}
	} 
}
}


.hover-gold{
	text-align: center;

	button{

    	width: 250px;
	    border-radius: 20px;
	    border: none;
	    margin: 0 50px;
	    
	    font-family: gotham-bold;
		padding: 0;
	    font-size: 16px;
	    position: relative;
	    height: 38px;
	    background: var(--color-grey1);

	    a{
	    	position: relative;
	    	z-index: 999;
	    	color: var(--color-black-main);
	    	text-decoration: none;
	    	padding: 8px 0 10px;
	    	width: 100%;
	    	display: inline-block;
	    }
    	
    	&:before{
    		content:"";
			background: linear-gradient(90deg,#a57d23,#f1dd7f 32%,#fbf2a9 50%,#f1dd7f 72%,#a57d23);	
			height: 100%;
		    width: 0%;
		    position: absolute;
		    left: 50%;
		    transform: translate(-50%);
		    top: 0;
		    border-radius: 20px;
		    opacity: 0;
		    transition: .3s width ease-out, .2s opacity ease-out;
    	}

    	&:hover:before{
    		transition: .3s width ease-out, .5s opacity ease-out;
    		opacity: 1;
    		width: 100%;
    	}
	}
}

.view-more.hover-gold{
	margin-top: 20px;
	button{
		background: #ccc;
	}
}

.home .navbar-inverse.navbar .navbar-nav>li>a:after{
	background-color: #fff;
}

.home .navbar-inverse.navbar .navbar-nav>li:first-child>a:after{
	background-color: #ffd64f;
}